import { useEffect, useRef } from 'react';
import gsap from 'gsap';

function Popup({ show, onMouseLeave, data }) {



  const videoRef = useRef(null);
  const info = useRef(null);


  const showControls = () => {
    videoRef.current.setAttribute('controls', true)
  };

  const hideControls = () => {
    videoRef.current.removeAttribute('controls')
  };
  useEffect(() => {
    const videoElement = videoRef.current;
    if (show) {
      if (videoElement) {
        videoElement.play();
      }
      gsap.to(info.current, { duration: 0.3, x: 0 });
    } else {
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
      gsap.to(info.current, { duration: 0.3, x: () => (info.current.offsetWidth + 25) * -1 });
    }
  }, [show]);

  return (
    <div className="info" ref={info} onMouseLeave={onMouseLeave}>
      <div className="popup-content">
        <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
        <video ref={videoRef} onMouseEnter={showControls} onMouseLeave={hideControls} src={data.video} playsInline loop></video>
      </div>
    </div>
  );
}

export default Popup;