import { useState, useEffect, useRef } from 'react';
import Logo from './components/Logo.js';
import Ball from './components/Ball.js';
import Menu from './components/Menu.js';


import './reset.scss';
import './App.scss';


function App() {
  const wrapper = useRef(null);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    const updateHeight = () => {

      wrapper.current.style.height = `${window.innerHeight}px`;

    };


    updateHeight();
    window.addEventListener('resize', updateHeight);


    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [loading]);


  const [data, setData] = useState({
    menuData: null,
    popupData: null,
    ballData: null
  });



  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://supercup.mikesmithdesign.co.uk/wp-json/wp/v2/pages/27?acf_format=standard&v=1.1');
        const data = await response.json();
        setData({
          menuData: {
            infoTitle: data.acf?.header?.info?.title,
            register: {
              title: data.acf?.header?.register?.title,
              email: data.acf?.header?.register?.email,
              subject: data.acf?.header?.register?.subject,
              message: data.acf?.header?.register?.message
            }
          },
          popupData: {
            text: data.acf?.header?.info?.text || 'The Studio Super Cup is an annual 5-a-side charity football tournament, for London Based Design Studios.',
            video: data.acf?.header?.info?.video
          },
          ballData: {
            videoSrc: data.acf?.main_video?.video,
            stylingCode: data.acf?.main_video?.sizing_code,
            speedIncrease: parseFloat(data.acf?.main_video?.speed_increase_factor),
            maxPlaybackRate: parseFloat(data.acf?.main_video?.max_playback_rate),
            speedReset: parseFloat(data.acf?.main_video?.speed_reset_factor)
          }
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return <div className="loading-screen" ref={wrapper}>Loading...</div>;
  }

  return (
    <>
      <Menu data={data} />
      <div className="wrapper" ref={wrapper}>
        <Ball data={data.ballData} />
        <Logo />
      </div>
    </>

  );
}

export default App;
