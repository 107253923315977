import { useState, useRef } from 'react';
import Popup from './Popup.js';

function Menu({ data }) {
  const infoButton = useRef(null);
  const [showPopup, setShowPopup] = useState(false);




  const togglePopup = () => {
    setShowPopup(!showPopup);
    infoButton.current.classList.toggle('active');
  };

  // const viewPopup = () => {
  //   setShowPopup(true);
  //   infoButton.current.classList.add('active');
  // };

  // const hidePopup = () => {
  //   setShowPopup(false);
  //   infoButton.current.classList.remove('active');
  // };

  return (
    <header>
      <ul>
        <li><button ref={infoButton} onClick={togglePopup}>{data.menuData.infoTitle}</button></li>
        <li>
          <a

            href={`mailto:${data.menuData.register.email}?subject=${encodeURIComponent(data.menuData.register.subject)}&body=${(data.menuData.register.message)}`}
          >
            {data.menuData.register.title}
          </a>
        </li>
      </ul>
      <Popup data={data.popupData} show={showPopup} onClose={togglePopup} />
    </header>

  )
}

export default Menu