import { useEffect, useRef, useState } from 'react';

function Ball({ data }) {
  const ball = useRef(null);
  const shadow = useRef(null);

  const scrollTimeout = useRef(null);
  const resetInterval = useRef(null);

  const [playbackRate, setPlaybackRate] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false);



  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  useEffect(() => {

    if (isSafari()) {
      return;
    }

    const handleWheel = (event) => {
      const delta = Math.max(-1, Math.min(1, event.deltaY || -event.detail));
      if (delta > 0) {  // Only handle downward scrolls
        const newPlaybackRate = Math.min(playbackRate + data.speedIncrease, data.maxPlaybackRate);

        setPlaybackRate(newPlaybackRate);
        setIsScrolling(true);

        clearTimeout(scrollTimeout.current);
        scrollTimeout.current = setTimeout(() => setIsScrolling(false), 50);
      }
    };

    document.addEventListener('wheel', handleWheel);

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, [playbackRate]);

  useEffect(() => {
    const ballElement = ball.current;

    if (ballElement) {
      ballElement.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  useEffect(() => {
    if (!isScrolling) {
      clearInterval(resetInterval.current);
      resetInterval.current = setInterval(() => {
        setPlaybackRate((prevRate) => {

          if (prevRate > 1) {
            const newRate = prevRate - (prevRate - 1) * data.speedReset;
            return newRate < 1 ? 1 : newRate;
          } else if (prevRate < 1) {
            const newRate = prevRate + (1 - prevRate) * data.speedReset;
            return newRate > 1 ? 1 : newRate;
          } else {
            clearInterval(resetInterval.current);
            return 1;
          }
        });
      }, 10); // Adjust the interval if needed
    }
    return () => clearInterval(resetInterval.current);
  }, [isScrolling]);


  const [ballPosition, setBallPosition] = useState({ x: 0, y: 0 });
  const [floatOffset, setFloatOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (data.stylingCode) {
      const style = document.createElement('style');
      style.textContent = data.stylingCode;
      document.head.append(style);

      return () => {
        document.head.removeChild(style);
      };
    }
  }, [data.stylingCode]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const ballElement = ball.current;
      if (ballElement) {
        const rect = ballElement.getBoundingClientRect();
        const ballCenterX = rect.left + rect.width / 2;
        const ballCenterY = rect.top + rect.height / 2;

        const distanceX = ballCenterX - event.clientX;
        const distanceY = ballCenterY - event.clientY;

        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

        const repulsionFactor = Math.min(100 / distance, 1);

        const moveX = distanceX * repulsionFactor * 0.075;
        const moveY = distanceY * repulsionFactor * 0.075;

        setBallPosition({
          x: moveX,
          y: moveY,
        });
      }
    };

    const applyFloatEffect = () => {
      setFloatOffset({
        x: (Math.random() - 0.5) * 5,
        y: (Math.random() - 0.5) * 5,
      });
    };

    document.addEventListener('mousemove', handleMouseMove);
    const floatInterval = setInterval(applyFloatEffect, 1000);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      clearInterval(floatInterval);
    };
  }, []);

  useEffect(() => {
    const ballElement = ball.current;
    const shadowElement = shadow.current;

    if (ballElement && shadowElement) {
      const totalOffsetX = ballPosition.x + floatOffset.x;
      const totalOffsetY = ballPosition.y + floatOffset.y;

      ballElement.style.transform = `translate(${totalOffsetX}px, ${totalOffsetY}px)`;
      const scale = 1 - (totalOffsetY / 200);
      shadowElement.style.transform = `translate(-50%, 0) translateX(${totalOffsetX}px) scaleY(${scale}) scaleX(${scale})`;
    }
  }, [ballPosition, floatOffset]);

  return (
    <div className="ball-wrapper">
      <video ref={ball} src={data.videoSrc} className="ball" autoPlay muted loop playsInline />
      <div ref={shadow} className="shadow" style={{ display: 'none' }}></div>
    </div>
  );
}

export default Ball;
